<template>
  <CRow class="pb-0">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <div v-if="budgetCapLoading">
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <CRow v-if="['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)" class="m-0 budget_cap">
          <CCol cols="6" xl="6" lg="6" md="6" sm="6">
            <div class="h-100 d-flex align-items-center">
              <span>{{$t('loyalty.Budget_cap_managers')}}</span>
            </div>
          </CCol>
          <CCol cols="6" xl="6" lg="6" md="6" sm="6">
            <quick-edit type="number" :step="10" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="budgetCap.budget" @input="setBudgetCap()">
              <span v-if="budgetCap.budget" class="m-0 d-flex align-items-center">
                <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken" class="mr-1"/>                
                <span><span class="count mr-1">{{budgetCap.budget}}</span>{{$t('loyalty.coins_per_employee_per_month')}}<i class="icon edit fas fa-pen"/></span>                
              </span>
              <span v-else>{{$t('loyalty.Set_budget_cap_for_managers')}}<i class="icon edit fas fa-pen"/></span>
            </quick-edit>
          </CCol>
        </CRow>          
      </div>      
    </CCol>
  </CRow>  
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'BudgetCaps',
  props: ['productLicenses'],
  components: {
    QuickEdit,
    loadingSpinner,
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      budgetCapLoading: false,
      budgetCapLoaded: false,
      budgetCap: {
        loyalty_budget_cap_id_external: null,
        budget: null     
      }     
    }
  },
  methods: {
    getBudgetCap() {
      // Start the loader
      if(this.budgetCapLoaded === false) this.budgetCapLoading = true;
      // Get the budget cals
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-cap')
        .then(res => {
          this.budgetCap = res.data.data;
          // Stop the loader
          this.budgetCapLoading = false;
          // Update the budgetCapLoaded value
          this.budgetCapLoaded = true;
        })
        .catch(err => {
          console.error(err);
          this.budgetCapLoading = false;
        });
    },
    setBudgetCap() {
      const budgetCapData = this.budgetCap;
      if (budgetCapData.loyalty_budget_cap_id_external) {
        this.updateBudgetCap(budgetCapData);
      } else {
        this.createBudgetCap(budgetCapData);
      }
    },
    createBudgetCap(budgetCapData) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-cap', { budgetCapData })
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('loyalty.Budget_cap_created'), type: 'is-success', duration: 2000 });
          this.budgetCap.loyalty_budget_cap_id_external = res.data.data.loyalty_budget_cap_id_external;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
    },
    updateBudgetCap(budgetCapData) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-cap/' + budgetCapData.loyalty_budget_cap_id_external, { budgetCapData })
        .then(() => {
          this.$buefy.toast.open({ message: this.$t('loyalty.Budget_cap_updated'), type: 'is-success', duration: 2000 });
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
    }
  },
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getBudgetCap();
  }
}
</script>