<template>
  <CRow class="pb-0">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <div v-if="sendersLoading">
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <CRow class="m-0">
          <CCol cols="6" xl="6" class="pb-0 text-left">
            <div v-if="checkPermission('loyalty.momentsenders.add')" class="d-flex align-items-center">
              <CButton class="btn_small" color="primary" @click="openSidebarRight('loyalty_moment_sender_details', { loyalty_moment_sender_id_external:  null });">                
                <span><i class="fas fa-plus mr-1"/>{{$t('common.Add_sender')}}</span>
              </CButton>
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getMomentSenders()">{{ $t('common.show_inactive') }}</b-switch>
            </div>
          </CCol>     
        </CRow>
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">        
            <b-table class="data_table" ref="senderTable" :data="senders" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
              <template slot-scope="props">
                <b-table-column field="image" width="2.5%">
                  <div v-if="props.row.profile_image_id"
                       class="item_image d-inline-block align-middle"
                       v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-sender/' + props.row.loyalty_moment_sender_id_external + '/' + props.row.profile_image_id + '/' + clientToken }"
                       @click="checkPermission('loyalty.momentsenders.edit') ? openSidebarRight('loyalty_moment_sender_details', { loyalty_moment_sender_id_external:  props.row.loyalty_moment_sender_id_external }) : null">
                  </div>
                  <div v-else
                       class="item_icon d-inline-block align-middle" 
                       @click="checkPermission('loyalty.momentsenders.edit') ? openSidebarRight('loyalty_moment_sender_details', { loyalty_moment_sender_id_external:  props.row.loyalty_moment_sender_id_external }) : null">
                    <i class="fas fa-user"/>               
                  </div>                     
                </b-table-column>                
                <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" width="30%">
                  <span>{{props.row.name}}</span>
                  <div class="item_tags d-flex flex-row">
                    <b-tag v-if="!props.row.is_active" class="mt-1 fit-content inactive">{{ $t('common.Inactive') }}</b-tag>
                  </div>                    
                </b-table-column>
                <b-table-column field="name" :label="$t('connect.Email_reply_to')" :searchable="searchEnabled" width="30%">
                  <span>{{props.row.email_reply_to}}</span>
                </b-table-column>
                <b-table-column field="actions" :visible="checkPermission('loyalty.momentsenders.edit')">
                  <div class="d-flex justify-content-lg-end">
                    <CButton class="m-0 d-inline-block" color="primary" @click="openSidebarRight('loyalty_moment_sender_details', { loyalty_moment_sender_id_external:  props.row.loyalty_moment_sender_id_external })">
                      <i class="fas fa-pen"/>
                    </CButton>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <div class="p-2 text-center">                  
                  <span>{{ $t('connect.No_senders_found') }}</span>
                </div>                
              </template>                              
            </b-table>        
          </CCol>          
        </CRow>        
      </div>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'Senders',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,     
      senders: [],
      sendersLoading: false,
      sendersLoaded: false,
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      showInactive: false,
    }
  },
  methods: {
    getMomentSenders() {
      // Start the loader
      if(this.sendersLoaded === false) this.sendersLoading = true;
      // Get the senders
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-senders/overview')
      .then(res => {
        this.senders = res.data.data;
        // Filter on active status (if necessary)
        if(!this.showInactive) this.senders = this.senders.filter( i => ['Y'].includes( i.active ) );
        // Check if the pagination should be enabled
        (this.senders.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.sendersLoading = false;
        // Update the sendersLoaded value
        this.sendersLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMomentSenderDetails(momentSenderIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-sender/' + momentSenderIdExternal)
      .then(res => {
        let updatedSender = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.senderTable !== undefined ) {
          // Get the sender index
          let senderIndex = this.$refs.senderTable.data.findIndex(x => x.loyalty_moment_sender_id_external == updatedSender.loyalty_moment_sender_id_external);
          // Update the is active value for the row
          this.$refs.senderTable.data[senderIndex].name = updatedSender.name;
          this.$refs.senderTable.data[senderIndex].profile_image_id = updatedSender.profile_image_id;
          this.$refs.senderTable.data[senderIndex].email_reply_to = updatedSender.email_reply_to;
          this.$refs.senderTable.data[senderIndex].updated = updatedSender.updated;
          this.$refs.senderTable.data[senderIndex].is_active = updatedSender.is_active;
          // Hide (newly) inactive senders  
          if(!this.showInactive) this.senders = this.senders.filter( i => ['Y'].includes( i.active ));
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      return this.platformPermissions.includes(permissionTag);
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token'); 

    this.getMomentSenders();
  
    this.$bus.$on('update_loyalty_moment_senders', () => {
      this.getMomentSenders();
    });

    this.$bus.$on('update_loyalty_moment_sender_details', (momentSenderIdExternal) => {
      this.getMomentSenderDetails(momentSenderIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_loyalty_moment_senders');
    this.$bus.$off('update_loyalty_moment_sender_details');
  }
}
</script>